const defaultAlertTimeout = 5000;

/* eslint-disable no-underscore-dangle */
const environmentConfig =
  window && window._environmentConfig ? window._environmentConfig : {};
/* eslint-enable no-underscore-dangle */

function getEnvironmentName() {
  const shortName = environmentConfig.environmentShortName || "UNKN";
  const longName = environmentConfig.environmentName || "UNKNOWN";
  return [shortName, longName];
}

export { getEnvironmentName, defaultAlertTimeout };
