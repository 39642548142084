import {
  faPen,
  faAnglesUp,
  faAnglesDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useField, useFormikContext } from "formik";
import React from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import * as Yup from "yup";

import Form from "~/components/form";
import Label from "~/components/form/label";
import SubmitButton from "~/components/form/submit";
import IconButton from "~/components/icon-button";
import { axios, useAlert } from "~/utils";

/* eslint-disable no-template-curly-in-string */
const schema = Yup.object().shape({
  min_count: Yup.number().integer("Must be an integer!").nullable(),
  lower_delta_limit: Yup.number().nullable(),
  upper_delta_limit: Yup.number().nullable(),
  enabled: Yup.bool().nullable(),
});
/* eslint-enable no-template-curly-in-string */

function BulkEditSegmentModal({ alert, segments, reFetch, onEdit }) {
  const [showModal, setShowModal] = React.useState(false);
  const onClose = () => {
    onEdit();
    setShowModal(false);
  };
  const initialValues = {
    min_count: null,
    lower_delta_limit: null,
    upper_delta_limit: null,
    enabled: null,
  };
  return (
    <>
      <Button disabled={!segments.length} onClick={() => setShowModal(true)}>
        <FontAwesomeIcon className="me-2" icon={faPen} />
        Bulk update
      </Button>
      <SegmentModal
        showModal={showModal}
        onClose={onClose}
        initialValues={initialValues}
        alert={alert}
        segments={segments}
        reFetch={reFetch}
        isBulk
      />
    </>
  );
}

function EditSegmentModal({ alert, segment, reFetch }) {
  const [showModal, setShowModal] = React.useState(false);
  const onClose = () => {
    setShowModal(false);
  };
  const initialValues = {
    min_count: segment.paramsSc.min_count,
    lower_delta_limit: segment.paramsSc.lower_delta_limit,
    upper_delta_limit: segment.paramsSc.upper_delta_limit,
    enabled: segment.enabled,
  };

  return (
    <>
      <IconButton.Edit onClick={() => setShowModal(true)} />
      <SegmentModal
        showModal={showModal}
        onClose={onClose}
        initialValues={initialValues}
        alert={alert}
        segments={[segment]}
        reFetch={reFetch}
        isBulk={false}
      />
    </>
  );
}

function SegmentModal({
  showModal,
  onClose,
  initialValues,
  alert,
  segments,
  reFetch,
  isBulk,
}) {
  const addAlert = useAlert();
  const onSubmit = (values) => {
    const { enabled, ...rawParams } = values;
    const params = Object.fromEntries(
      Object.entries(rawParams).filter(([, v]) => v !== null && v !== "")
    );
    const data = {
      segments: segments.map((v) => v.id),
      enabled,
      params,
    };
    return axios({
      url: "api/v2/update-segments",
      method: "POST",
      data,
    }).then(() => {
      const message =
        segments.length > 1
          ? "Segments have been updated"
          : "Segment has been updated";
      addAlert(message, "success");
      onClose();
      reFetch();
    });
  };

  return (
    <Modal size="lg" show={showModal} onHide={onClose} centered>
      <Modal.Header className="text-center" closeButton>
        <Modal.Title className="w-100">
          {isBulk ? "Bulk edit thresholds" : "Edit thresholds"}
        </Modal.Title>
      </Modal.Header>
      <Form
        form={false}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <>
          <Modal.Body className="px-5">
            <p>
              <b>Name:</b> {alert.name}
            </p>
            <DimensionsViewer
              dimensions={alert.dimensions}
              segments={segments}
              isBulk={isBulk}
            />
            <Form.InnerForm>
              <Form.NonFieldErrors />

              <Row>
                <InputCol label="Min Count" name="min_count">
                  <Form.Control
                    type="number"
                    step="1"
                    min="0"
                    name="min_count"
                  />
                </InputCol>

                <InputCol label="Lower threshold" name="lower_delta_limit">
                  <Form.Control
                    type="number"
                    step="any"
                    name="lower_delta_limit"
                  />
                </InputCol>

                <InputCol label="Upper threshold" name="upper_delta_limit">
                  <Form.Control
                    type="number"
                    step="any"
                    name="upper_delta_limit"
                  />
                </InputCol>

                <InputCol label="Active" name="enabled">
                  <div
                    className="d-flex align-items-center"
                    style={{ minHeight: "33.5px" }}
                  >
                    {isBulk ? (
                      <NullableCheck name="enabled" />
                    ) : (
                      <Form.Check name="enabled" />
                    )}
                  </div>
                </InputCol>
              </Row>

              {/* this input is needed to allow to submit form by pressing enter */}
              <input type="submit" className="d-none" />
            </Form.InnerForm>
          </Modal.Body>
          <Modal.Footer>
            <SmartSubmitButton />
          </Modal.Footer>
        </>
      </Form>
    </Modal>
  );
}

function SmartSubmitButton() {
  const { values, initialValues } = useFormikContext();
  const disabled = Object.entries(values).every(
    ([k, v]) => v === null || v === "" || v === initialValues[k]
  );
  return <SubmitButton icon={false} title="Confirm" disabled={disabled} />;
}

function NullableCheck({ name }) {
  const [{ value, onBlur }, , { setValue }] = useField(name);
  const indeterminate = value === null;
  const checked = !!value;
  const cycleState = () => {
    /* eslint-disable no-nested-ternary */
    const nextValue = indeterminate ? true : value ? false : null;
    /* eslint-enable no-nested-ternary */
    setValue(nextValue);
  };

  const cRef = React.useRef();
  React.useEffect(() => {
    cRef.current.indeterminate = indeterminate;
  }, [cRef, indeterminate]);

  return (
    <input
      ref={cRef}
      type="checkbox"
      className="form-check-input"
      name={name}
      checked={checked}
      onChange={cycleState}
      onBlur={onBlur}
    />
  );
}

function DimensionsViewer({ dimensions, segments, isBulk }) {
  const [collapsed, setCollapsed] = React.useState(true);
  if (!isBulk) {
    return (
      <p>
        <b>Segment: </b>
        {dimensions.map((v) => segments[0].selectorSc[v]).join(", ")}
      </p>
    );
  }
  const previewLength = 2;
  const needExpand = segments.length > 2;
  const labels = segments.map((s) =>
    dimensions.map((v) => s.selectorSc[v]).join(", ")
  );
  const shownLabels =
    collapsed && needExpand
      ? [...labels.slice(0, previewLength), "..."]
      : labels;
  const title = collapsed ? "Show" : "Hide";
  const icon = collapsed ? faAnglesDown : faAnglesUp;
  return (
    <div className="d-flex align-items-start">
      <b className="me-2">Segments: </b>
      <div
        className={classnames("d-flex flex-column", {
          "align-items-center": collapsed,
        })}
      >
        {shownLabels.map((v) => (
          <span key={v}>{v}</span>
        ))}
      </div>
      {needExpand && (
        <IconButton
          title={title}
          icon={icon}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}
    </div>
  );
}

function ChangedIndicator({ name }) {
  const { value, initialValue } = useField(name)[1];
  const indicator =
    value === "" || value === initialValue ? <i>Not changed</i> : null;
  return <div style={{ minHeight: "20px" }}>{indicator}</div>;
}

function InputCol({ label, name, children }) {
  return (
    <Col xs={3}>
      <Label direction="vertical" text={label}>
        {children}
        <Form.Control.ErrorFeedback name={name} />
        <ChangedIndicator name={name} />
      </Label>
    </Col>
  );
}

export default EditSegmentModal;
export { BulkEditSegmentModal };
